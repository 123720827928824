import { InquirerResponse as InquirerResponseForm } from '@bgea-js/form-templates';
import "../../../../src/content/inquirer-response-page/inquirer-styles.scss";
import privacyPolicyCopy from "../../../../src/content/inquirer-response-page/privacyPolicyCopy";
import inquirerConfirmation from "../../../../src/content/inquirer-response-page/inquirerConfirmation";
import * as React from 'react';
export default {
  InquirerResponseForm,
  privacyPolicyCopy,
  inquirerConfirmation,
  React
};