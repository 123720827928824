import React, { useState } from 'react';
import DigitalForm from '../content/decision-magazine-2024 /form-digital.mdx';
import HardCopyForm from '../content/decision-magazine-2024 /form-hard-copy.mdx';
import '../content/decision-magazine-2024 /dropdown.scss';

const DecisionMagazineDD = () => {
  const [selectedForm, setSelectedForm] = useState('');

  const handleDropdownChange = (event) => {
    const value = event.target.value;
    setSelectedForm(value);
  };

  return (
    <div className="custom-dropdown">
      <label className="dropdown-label" htmlFor="formDropdown">
        Select Physical or Digital Copy:
      </label>
      <div className="dropdown-wrapper">
        <select className="custom-select" onChange={handleDropdownChange}>
          <option value="">Select</option>
          <option value="form1">EMAIL ME THE DIGITAL COPY</option>
          <option value="form2">MAIL ME A PHYSICAL COPY</option>
        </select>
        <span className="dropdown-icon">&#9660;</span>
      </div>
      <div className="dropdown-section">
        {selectedForm === 'form1' && <DigitalForm />}
        {selectedForm === 'form2' && <HardCopyForm />}
      </div>
    </div>
  );
};

export default DecisionMagazineDD;
