import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { LandingPage as LandingPageForm } from '@bgea-js/form-templates';
import decisionMagazineHardCopy from "./customConfirmationHardcopy";
import './decision-magazine.scss';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p className="supply"><strong>While supplies last; One per household; Please allow 2 to 4 weeks for delivery; U.S. residents only.</strong></p>
    <div className="form-container-hc">
      <LandingPageForm SESourceCode="BD24ALFEG" addressVisible={['street', 'streetTwo', 'cityTownRegion', 'stateProvince', 'zipPostalCode']} buttonText="SEND MY FREE ISSUE" checkBox18Text="I am 18 or older*" collectionType={468} country={['US']} customConfirmation={decisionMagazineHardCopy} destination={2} emailId="NewNames" emailTemplateCampaign="NN Decision Magazine Hard Copy" esubCodes={['BGEA', 'DMAGNL']} isChecked lng="en" maxPostalCodeLength={5} minPostalCodeLength={5} priceCode={[{
        amount: 0,
        productId: 'DMOCTKIT24',
        quantity: 1,
        warehouse: 'DECISION'
      }]} privacyPolicyCopy="By providing your personal details, you accept that we will use your personal details to communicate with you about ministry updates and opportunities from Billy Graham Evangelistic Association.  <a href='https://billygraham.org/privacy-policy/' target='_blank'>  View our full Privacy Policy. </a>" show18Checkbox specialCode={[]} mdxType="LandingPageForm" />
 </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      