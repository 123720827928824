import * as React from 'react';
import './christian-life-witness-course-general.scss'

import { MdInsertDriveFile } from 'react-icons/md';

//const thumbnailMain =
//  'https://cfvod.kaltura.com/p/650742/sp/65074200/thumbnail/entry_id/1_kj9iv6vt/width/384/height/216/';
const videos = [
  {
    id: '1_0gsn4pao',
    title: 'Lession 1 The Effective Christian Life',
    thumbnail:
      'https://cfvod.kaltura.com/p/650742/sp/65074200/thumbnail/entry_id/1_0gsn4pao/width/384/height/216/', // Replace with actual thumbnail paths
  },
  {
    id: '1_3elvbktb',
    title: "Lesson 2 The Christian's Witness",
    thumbnail:
      'https://cfvod.kaltura.com/p/650742/sp/65074200/thumbnail/entry_id/1_3elvbktb/width/384/height/216/',
  },
  {
    id: '1_l6r2m359',
    title: 'Lesson 3 Follow_Up',
    thumbnail:
      'https://cfvod.kaltura.com/p/650742/sp/65074200/thumbnail/entry_id/1_l6r2m359/width/384/height/216/',
  },
];
const KalturaPlayer = ({ kalturaId, title, thumbnail }) => {
  // const iframeSrc = `https://cdnapisec.kaltura.com/p/123456/sp/12345600/embedIframeJs/uiconf_id/12345678/partner_id/123456?iframeembed=true&playerId=kaltura_player&entry_id=${kalturaId}&flashvars[streamerType]=auto`;
  const iframeSrc = `https://cdnapisec.kaltura.com/p/650742/sp/65074200/embedIframeJs/uiconf_id/49982243/partner_id/650742?iframeembed=true&playerId=kaltura_player_1694532980&entry_id=${kalturaId}`;
  return (
    <div>
      {/* <h2>{title}</h2> */}
      <iframe
        className="player-video"
        src={iframeSrc}
        width="83%"
        height="650"
        allowFullScreen
        frameBorder="0"
        title={title}
      ></iframe>
    </div>
  );
};

function VideoList({ pageContext }) {
  const [currentVideo, setCurrentVideo] = React.useState(videos[0]);
  return (
    <section className="container-clwc" id="clwc-general">
      <div className="row align-middle align-center ">
        {/* <div className='small-12 large-9 columns body-copy single-content'> */}

        <div>
          <p className="mt-3">
            This 3-part course will help deepen your walk with Christ, teach
            practical ways to share your faith, and train you to disciple those{' '}
             who respond to the Gospel.
          </p>
          <div className="main-video">
            <KalturaPlayer
              kalturaId={currentVideo.id}
              title={currentVideo.title}
              thumbnail={currentVideo.thumbnail}
            />
          </div>
          <div className="thumbnail-videos">
            {videos.map((video) => (
              <div
                key={video.id}
                className="thumbnail"
                onClick={() => setCurrentVideo(video)}
              >
                <img
                  className="image-kaltura"
                  src={video.thumbnail}
                  alt={video.title}
                />
                <p className="subheader">{video.title}</p>
              </div>
            ))}
          </div>
          {/* </div> */}
          <div className="content-below">
            <p>
              The following books are referenced during the course. They can be
              downloaded or printed to help you follow along with the
              lessons. These materials are for personal use only and should not
              be copied or distributed.
            </p>
            <p>
              <a
                href="/resources/18747_clwc_course_book.pdf"
                class="link"
                target="_blank"
              >
                <MdInsertDriveFile size={24} /> Christian Life and Witness Course Book
              </a>
            </p>
            <p>
              <a
                href="/resources/21174-Living-In-Christ.pdf"
                class="link"
                target="_blank"
              >
                <MdInsertDriveFile size={24} /> Living in Christ Book
              </a>
            </p>
            <p className="inside-content">
              <a
                href="/resources/12653_growing_in_christ_rev_final.pdf"
                class="link"
                target="_blank"
              >
                <MdInsertDriveFile size={24} /> Growing in Christ
              </a>
            </p>
            <p className="inside-content">
              <a
                href="/resources/18484_steps_to_peace_with_god.pdf"
                class="link"
                target="_blank"
              >
                <MdInsertDriveFile size={24} /> Steps to Peace with God
              </a>
            </p>
            <p className="inside-content">
              <a
                href="/resources/12788_bring_a_friend.pdf"
                class="link"
                target="_blank"
              >
                <MdInsertDriveFile size={24} /> Bring a Friend
              </a>
            </p>
          </div> 
        </div>
      </div>
    </section>
  );
}

export default VideoList;
