import React from 'react';

const electionConfirmation = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Great News {firstName}! Your prayer guide should deliver in 2 to 4 weeks.
    </h2>
    <br></br>
    <div>
      <p>
      We praise the Lord for your heart to know what the Bible says about critical issues facing our nation and we pray this new resource will encourage you to keep <em>“praying at all times in the Spirit, with all prayer and supplication”</em> (Ephesians 6:18, ESV).
      </p>
    </div>
  </div>
);

export default electionConfirmation;
