import React from 'react';

const inquirerConfirmation = () => (
  <div>
    <h1 className="title">Grazie!</h1>
    <div className="block">
      <p>L'invio è riuscito.</p>
    </div>
  </div>
);

export default inquirerConfirmation;
