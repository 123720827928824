import React from 'react';

const privacyPolicyCopy = () => (
  <div>
    <p>
      Fornendo i tuoi dati personali (comprese le tue opinioni religiose), ci
      consenti: di utilizzare i tuoi dati per rimanere in contatto sul Noi
      Festival e per informarti sulle altre attività del ministero della BGEA; e
      l'uso di questi dati per gestire il tuo coinvolgimento con la BGEA. Questi
      dati saranno conservati dalla BGEA negli Stati Uniti e / o in Italia (e
      potrebbero essere trasmessi ai nostri partner ministeriali nel tuo paese,
      come BGEA - Italia, per l'elaborazione) solo per gli scopi di cui sopra.
      Per gestire le preferenze e per saperne di più su come gestiamo i tuoi
      dati, consulta la privacy politica della BGEA su 
      <a href="https://noifestival.it/privacy-politica/" target="_blank" rel="noreferrer"> noifestival.it/privacy-politica</a>.
    </p>
  </div>
);

export default privacyPolicyCopy;
